<template>
  <div style="height: inherit">
    <!-- Filter -->
    <b-row>
      <b-col>
        <b-card class="search-card">
          <b-row>
            <b-col class="text-center mt-2 mb-2">
              <h1 class="text-primary text-center">
                Search <u>{{ selectedDiscipline }}</u> tournaments by name or organizer
              </h1>
            </b-col>
          </b-row>

          <!-- Tournament search input   -->
          <b-row class="justify-content-center">
            <b-col
              class="mb-2"
              md="12"
              lg="6"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                  v-model="searchTerm"
                  placeholder="What are you looking for?"
                  size="lg"
                  type="search"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    height="20px"
                    icon="SearchIcon"
                    width="20px"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>
          <!-- End Tournament search input   -->

          <b-row class="mb-2">
            <b-col
              class="d-flex justify-content-center"
              lg="12"
            >
              <div
                class="d-flex flex-wrap justify-content-center"
              >
                <b-form-checkbox
                  v-model="allPlatforms"
                  class="m-1"
                  @change="toggleCheckAll()"
                >
                  All
                </b-form-checkbox>
                <b-form-checkbox
                  v-for="platform in platformOptions"
                  :key="platform.value"
                  v-model="platforms"
                  :value="platform.value"
                  class="m-1"
                >
                  {{ platform.text }}
                </b-form-checkbox>
              </div>
            </b-col>
          </b-row>

          <!-- Entities filter   -->
          <b-row class="justify-content-center">
            <b-col class="d-flex justify-content-center">
              <div class="d-flex justify-content-end mr-1">
                Filters:
              </div>
              <div class="d-flex flex-row flex-wrap">
                <b-form-checkbox
                  v-for="filter in filtersOptions"
                  :key="filter.value"
                  v-model="searchFilters"
                  :value="filter.value"
                  class="mr-2"
                >
                  {{ filter.text }}
                </b-form-checkbox>
              </div>
            </b-col>
          </b-row>
          <!-- End Entities filter   -->
        </b-card>
      </b-col>
    </b-row>
    <!-- End Filter -->

    <!-- Search results -->
    <b-row v-if="!isLoading && (!searchResults || searchResults.length === 0)">
      <b-col class="d-flex flex-column align-items-center">
        <feather-icon
          class="m-2"
          icon="FrownIcon"
          size="50"
        />
        No data matches you search criteria
      </b-col>
    </b-row>

    <b-row v-if="isLoading">
      <b-col class="d-flex flex-column align-items-center">
        <b-spinner label="Loading..." />
      </b-col>
    </b-row>
    <!-- End Search results -->

    <!-- Search results -->
    <b-row
      v-if="!isLoading && searchResults && searchResults.length > 0"
      class="justify-content-start"
    >
      <b-col
        v-for="(searchResultItem, index) in searchResults"
        :key="index"
        sm="12"
        md="6"
        lg="3"
        class="mb-1"
      >
        <tournament-info-card :tournament="searchResultItem" />
      </b-col>
    </b-row>
    <!-- End Search results -->
  </div>
</template>

<script>

import { onMounted, ref, watch } from '@vue/composition-api'
import {
  BCard, BCol, BFormCheckbox, BFormInput, BInputGroup, BInputGroupAppend, BRow, BSpinner,
} from 'bootstrap-vue'
import { useRoute, useRouter } from 'vue2-helpers/vue-router'
import Disciplines from '@/constants/Disciplines'
import TournamentInfoCard from '@/views/tournament/TournamentInfoCard.vue'
// eslint-disable-next-line import/no-cycle
import useApi from '@/api/useApi'

export default {
  components: {
    TournamentInfoCard,
    BCard,
    BCol,
    BRow,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BSpinner,
    BFormCheckbox,
  },
  setup() {
    const router = useRouter()
    const route = useRoute()

    const filtersOptions = [
      {
        text: 'Entry fee',
        value: 'entry_fee',
      },
      {
        text: 'Include passed',
        value: 'passed',
      },
      {
        text: 'Offline',
        value: 'offline',
      },
    ]

    const platformOptions = [
      {
        value: 1,
        text: 'PC',
      },
      {
        value: 2,
        text: 'Playstation 4',
      },
      {
        value: 3,
        text: 'Playstation 5',
      },
      {
        value: 4,
        text: 'Xbox One',
      },
      {
        value: 5,
        text: 'Switch',
      },
      {
        value: 6,
        text: 'Mobile',
      },
    ]

    const isLoading = ref(false)
    const searchTerm = ref(route?.query?.q ? route.query.q : '')
    const searchResults = ref([])
    const allPlatforms = ref(true)
    const platforms = ref(route?.query.f ? JSON.parse(route?.query.p) : [0])
    const selectedDiscipline = ref(Disciplines.find(discipline => discipline.slug === route?.params?.game)?.title || null)
    const searchFilters = ref(route?.query.f ? JSON.parse(route?.query.f) : null)

    // eslint-disable-next-line no-shadow
    const fetchData = async () => {
      isLoading.value = true

      const {
        success,
        data,
      } = await useApi()
        .tournament
        .fetchTournaments(
          allPlatforms.value === true ? null : platforms.value,
          searchFilters.value ? searchFilters.value.includes('entry_fee') : null,
          searchFilters.value ? searchFilters.value.includes('passed') : null,
          searchFilters.value ? searchFilters.value.includes('offline') : null,
            Disciplines.find(discipline => discipline.slug === route?.params?.game)?.id || null,
            searchTerm.value,
            null,
        )

      searchResults.value = success ? data : []

      const routeQuery = {
        ...route?.query || null,
        f: searchFilters.value ? JSON.stringify(searchFilters.value) : null,
        q: searchTerm.value || null,
        p: searchFilters.value ? JSON.stringify(platforms.value) : null,
      }

      router.push({ query: routeQuery })
        .catch(() => {
        })

      isLoading.value = false
    }

    watch(
      () => route.params.game,
      async () => {
        selectedDiscipline.value = Disciplines.find(discipline => discipline.slug === route?.params?.game)?.title || null

        await fetchData()
      },
    )

    watch(searchTerm, newValue => {
      searchTerm.value = newValue

      fetchData()
    })

    watch(platforms, () => {
      allPlatforms.value = false

      const indexOfAllSelectedPlatforms = platforms.value.indexOf(0)

      if (indexOfAllSelectedPlatforms !== -1) {
        platforms.value.splice(indexOfAllSelectedPlatforms, 1)
      }

      fetchData()
    })

    watch(searchFilters, () => {
      fetchData()
    })

    const toggleCheckAll = () => {
      allPlatforms.value = true

      platforms.value = [0]
    }

    onMounted(() => {
      fetchData()
    })

    return {
      searchTerm,
      searchResults,
      isLoading,
      filtersOptions,
      searchFilters,
      selectedDiscipline,
      toggleCheckAll,
      platforms,
      allPlatforms,
      platformOptions,
    }
  },
}
</script>

<style>

</style>
