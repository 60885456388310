var render = function () {
  var _vm$tournament, _vm$tournament2, _vm$tournament3, _vm$tournament4, _vm$tournament4$disci, _vm$tournament5, _vm$tournament5$disci, _vm$tournament6, _vm$tournament7, _vm$tournament8, _vm$tournament9;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-link', {
    key: (_vm$tournament = _vm.tournament) === null || _vm$tournament === void 0 ? void 0 : _vm$tournament.id,
    attrs: {
      "to": {
        name: 'tournament-info',
        params: {
          id: (_vm$tournament2 = _vm.tournament) === null || _vm$tournament2 === void 0 ? void 0 : _vm$tournament2.id
        }
      }
    }
  }, [_c('b-card', {
    staticClass: "card-profile p-2 w-100 h-100 m-0",
    attrs: {
      "no-body": ""
    }
  }, [_c('h3', {
    staticStyle: {
      "min-height": "50px"
    }
  }, [_vm._v(" " + _vm._s((_vm$tournament3 = _vm.tournament) === null || _vm$tournament3 === void 0 ? void 0 : _vm$tournament3.name) + " ")]), _c('b-row', {
    staticClass: "tournament-detail-item"
  }, [_c('b-col', [_c('div', [_c('b-avatar', {
    staticClass: "mb-1",
    attrs: {
      "src": _vm.getDisciplineImage((_vm$tournament4 = _vm.tournament) === null || _vm$tournament4 === void 0 ? void 0 : (_vm$tournament4$disci = _vm$tournament4.discipline) === null || _vm$tournament4$disci === void 0 ? void 0 : _vm$tournament4$disci.id) || null,
      "size": "42",
      "square": "",
      "variant": "primary"
    }
  })], 1), _c('div', {
    staticClass: "section-label"
  }, [_c('strong', [_vm._v(_vm._s((_vm$tournament5 = _vm.tournament) === null || _vm$tournament5 === void 0 ? void 0 : (_vm$tournament5$disci = _vm$tournament5.discipline) === null || _vm$tournament5$disci === void 0 ? void 0 : _vm$tournament5$disci.title))])])])], 1), _c('b-row', {
    staticClass: "tournament-detail-item"
  }, [_c('b-col', [_c('span', [_vm._v(_vm._s(_vm.participantsJoined))])])], 1), _vm.tournament.status_id !== _vm.TournamentStatusType.IN_PROGRESS || _vm.tournament.status_id !== _vm.TournamentStatusType.FINISHED || _vm.tournament.status_id !== _vm.TournamentStatusType.CLOSED ? _c('b-row', {
    staticClass: "m-1 tournament-detail-item"
  }, [_c('b-col', [_c('span', [_vm._v("Start - " + _vm._s(_vm.utcToTimezoneFormat(_vm.tournament.start_at, _vm.tournament.timezone_id)))])])], 1) : _vm._e(), _vm.showTimeTillStart ? _c('b-row', {
    staticClass: "tournament-detail-item"
  }, [_c('b-col', [_c('span', [_vm._v(_vm._s(_vm.timeTillStart) + " left to join")])])], 1) : _vm._e(), _c('b-row', {
    staticClass: "tournament-detail-item"
  }, [_c('b-col', [_c('span', [_vm._v("Platforms:")]), _vm._l((_vm$tournament6 = _vm.tournament) === null || _vm$tournament6 === void 0 ? void 0 : _vm$tournament6.platforms, function (platform, index) {
    return _c('b-badge', {
      key: index,
      staticClass: "ml-1 mr-1",
      attrs: {
        "size": "sm",
        "variant": "light-primary"
      }
    }, [_vm._v(" " + _vm._s(platform.title) + " ")]);
  })], 2)], 1), _vm.showCheckin ? _c('b-row', {
    staticClass: "tournament-detail-item"
  }, [_c('b-col', [_c('span', [_vm._v("Check-in (" + _vm._s(_vm.checkin) + " minutes)")])])], 1) : _vm._e(), _c('b-row', {
    staticClass: "m-1 mt-auto"
  }, [_c('b-col', {
    staticClass: "d-flex justify-content-center",
    attrs: {
      "sm": "12"
    }
  }, [(_vm$tournament7 = _vm.tournament) !== null && _vm$tournament7 !== void 0 && _vm$tournament7.entry_fee ? _c('span', [_c('b-badge', {
    attrs: {
      "variant": "light-warning"
    }
  }, [_vm._v(" " + _vm._s(((_vm$tournament8 = _vm.tournament) === null || _vm$tournament8 === void 0 ? void 0 : _vm$tournament8.entry_fee) || 0) + " € ")])], 1) : _vm._e(), !((_vm$tournament9 = _vm.tournament) !== null && _vm$tournament9 !== void 0 && _vm$tournament9.entry_fee) ? _c('span', [_c('b-badge', {
    attrs: {
      "variant": "light-success"
    }
  }, [_vm._v(" NO ENTRY FREE ")])], 1) : _vm._e()])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }