<template>
  <b-link
    :key="tournament?.id"
    :to="{name: 'tournament-info', params: {id: tournament?.id}}"
  >
    <b-card
      class="card-profile p-2 w-100 h-100 m-0"
      no-body
    >

      <h3
        style="min-height: 50px;"
      >
        {{ tournament?.name }}
      </h3>

      <b-row class="tournament-detail-item">
        <b-col>
          <div>
            <b-avatar
              :src="getDisciplineImage(tournament?.discipline?.id) || null"
              size="42"
              square
              variant="primary"
              class="mb-1"
            />
          </div>
          <div class="section-label">
            <strong>{{ tournament?.discipline?.title }}</strong>
          </div>
        </b-col>
      </b-row>

      <b-row class="tournament-detail-item">
        <b-col>
          <span>{{ participantsJoined }}</span>
        </b-col>
      </b-row>

      <b-row
        v-if="tournament.status_id !== TournamentStatusType.IN_PROGRESS || tournament.status_id !== TournamentStatusType.FINISHED|| tournament.status_id !== TournamentStatusType.CLOSED"
        class="m-1 tournament-detail-item"
      >

        <b-col>
          <span>Start - {{ utcToTimezoneFormat(tournament.start_at, tournament.timezone_id) }}</span>
        </b-col>
      </b-row>

      <b-row
        v-if="showTimeTillStart"
        class="tournament-detail-item"
      >
        <b-col>
          <span>{{ timeTillStart }} left to join</span>
        </b-col>
      </b-row>

      <b-row class="tournament-detail-item">
        <b-col>
          <span>Platforms:</span>
          <b-badge
            v-for="(platform, index) in tournament?.platforms"
            :key="index"
            class="ml-1 mr-1"
            size="sm"
            variant="light-primary"
          >
            {{ platform.title }}
          </b-badge>
        </b-col>
      </b-row>

      <b-row
        v-if="showCheckin"
        class="tournament-detail-item"
      >
        <b-col>
          <span>Check-in ({{ checkin }} minutes)</span>
        </b-col>
      </b-row>

      <b-row class="m-1 mt-auto">
        <b-col
          sm="12"
          class="d-flex justify-content-center"
        >
          <span v-if="tournament?.entry_fee">
            <b-badge
              variant="light-warning"
            >
              {{ tournament?.entry_fee || 0 }} €
            </b-badge>
          </span>
          <span v-if="!tournament?.entry_fee">
            <b-badge
              variant="light-success"
            >
              NO ENTRY FREE
            </b-badge>
          </span>
        </b-col>
      </b-row>
    </b-card>
  </b-link>
</template>

<script>
import {
  BAvatar, BBadge, BCard, BCol, BLink, BRow,
} from 'bootstrap-vue'
import { timeTillDate, utcToTimezoneFormat } from '@/common/date-utils'
import TournamentParticipantType from '@/constants/TournamentParticipantType'
import TournamentStatusType from '@/constants/TournamentStatusType'
import { getDisciplineImage } from '@/views/common'
import { isAfter, isBefore } from 'date-fns'
import intervalToDuration from 'date-fns/intervalToDuration'

export default {
  components: {
    BBadge,
    BCard,
    BAvatar,
    BLink,
    BRow,
    BCol,
  },
  props: {
    tournament: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      TournamentStatusType,
      getDisciplineImage,
    }
  },
  computed: {
    checkin() {
      if (
        !this.tournament.value
          || !this.tournament.value.enable_checkin
          || !this.tournament.value.checkin_start_at
          || !this.tournament.value.checkin_close_at
      ) {
        return null
      }

      return this.tournament?.checkin_close_at && this.tournament?.checkin_start_at
        ? intervalToDuration({
          start: new Date(this.tournament.checkin_start_at),
          end: new Date(this.tournament.checkin_close_at),
        }).minutes
        : null
    },
    showCheckin() {
      if (!this.tournament?.enable_checkin || !this.tournament?.checkin_close_at || !this.tournament?.checkin_start_at) {
        return false
      }

      // show checkin if checkin started && checkin not closed
      return isAfter(new Date(this.tournament.checkin_start_at), new Date()) && isBefore(new Date(this.tournament.checkin_close_at), new Date())
    },
    showTimeTillStart() {
      return this.tournament?.status_id === TournamentStatusType.PUBLISHED && isBefore(new Date(), new Date(this.tournament?.start_at))
    },
    timeTillStart() {
      return timeTillDate(this.tournament?.start_at, this.tournament?.timezone_id)
    },
    participantsJoined() {
      return `${this.tournament && this.tournament.participants ? this.tournament.participants.length : 0} of ${this.tournament && this.tournament.participants_number
        ? this.tournament.participants_number
        : '?'
      } ${this.tournament && this.tournament.participant_type_id === TournamentParticipantType.TEAM ? 'teams' : 'players'} joined`
    },
  },
  methods: { utcToTimezoneFormat },
}
</script>

<style lang="scss">
  .tournament-detail-item {
    margin: 10px;
    color: #d0d2d6;
  }

  .card-profile {
    min-height: 350px;
  }

</style>
